/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
registerLocale('es', es);
import 'react-datepicker/dist/react-datepicker.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
	Grid,
	FormControl,
	Button,
	Input,
	TextField,
	Autocomplete,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import stationLogo from '../../assets/images/create-station-logo.png';
import NewStationStyled from '../../styles/pages/newStation';
import {
	addStation,
	deleteStation,
	getStationDetail,
	updateStationDetail,
	ClearError,
} from '../../redux/slice/stationSlice';
import TagsInput from '../../components/tag-component';
import TimeZoneList from '../../utils/timezone.json';
import { isUrlValid } from '../../utils/utils';
import SimpleBackdrop from '../../components/loader';
import UserDetail from '../../components/station/userDetail';
import ConfirmDelete from '../../components/delete-component';
import ErrorMsg from '../../components/ErrorMsg';

const NewStation = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let params = useParams();
	const stationState = useSelector(state => state.station);
	const [title, setTitle] = useState('');
	const [selectedStation, setSelectedSation] = useState('');
	const [inputValues, setInputValues] = useState({
		stationName: '',
		stationLink: '',
		contractExpiredate: '',
		stationDescription: '',
		tags: [],
		contentType: '',
		organization: '',
		stationLocation: '',
		timeZone: '',
		stationImage: '',
	});
	const [errors, setErrors] = useState({});
	const [isOpenAlert, setIsOpenAlert] = useState(false);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	useEffect(() => {
		if (params.id) {
			setSelectedSation(params.id);
			dispatch(getStationDetail({ id: params.id, fetchDetail: fetchStationDetail }));
		} else {
			setSelectedSation();
		}
	}, [params]);

	useEffect(() => {
		dispatch(ClearError());
	}, []);

	const fetchStationDetail = (detail) => {
		setTitle(detail.stationName);
		detail.contractExpiredate = moment(new Date(detail.contractExpiredate.seconds * 1000));
		setInputValues({ ...detail });
	};

	const uploadFile = () => {
		document.getElementById('inputImage').click();
	};

	const handleUploadFile = (event) => {
		const image = event.target.files[0];
		const reader = new FileReader();
		const error = { ...errors };
		let size = 0;
		if (image) {
			size = Math.ceil(image.size / (1024 * 1024));
		}
		if (!image.name.match(/\.(png)$/)) {
			error['image'] = 'Please select valid image. only png format allowed';
		} else if (size > 5) {
			error['image'] = 'Please select maximum 5mb image';
		} else {
			reader.readAsDataURL(image);
			reader.onload = () => {
				const imageUrl = reader.result;
				const img = new Image;
				img.src = imageUrl;
				const maxWidth = 1920;
				const maxHeight = 1080;
				img.onload = () => {
					if (img.width > maxWidth || img.height > maxHeight) {
						error['image'] = `Please choose one with maximum dimensions of ${maxWidth}x${maxHeight}.`;
						setErrors({ ...error });
					} else {
						error['image'] = '';
						setErrors({ ...error });
						if (inputValues?.stationImage?.includes('firebasestorage')) {
							setInputValues({
								...inputValues,
								oldImageUrl: inputValues.stationImage,
								stationImage: imageUrl,
							});
						} else {
							getImageUrl(imageUrl);
						}
					}
				};
			};
		}
		setErrors(error);
	};
	const getImageUrl = (data) => {
		setInputValues({ ...inputValues, stationImage: data });
	};

	const handleSelecetedTags = (tags) => {
		setInputValues({
			...inputValues,
			tags,
		});
	};

	const handleSubmit = () => {
		if (validate()) {
			if (selectedStation) {
				const data = {
					...inputValues,
					contractExpiredate: new Date(inputValues.contractExpiredate),
					id: selectedStation
				};
				delete data.users;
				dispatch(updateStationDetail({ data, navigateHomePage }));
			} else {
				const data = {
					...inputValues,
					createdDate: new Date().getTime(),
					isDeleted: false,
				};
				const stationImage = inputValues.stationImage;
				dispatch(addStation({ detail: data, stationImage, navigateHomePage }));
			}
		}
	};
	const navigateHomePage = () => {
		navigate('/');
	};

	const handleOK = () => {
		setIsOpenAlert(!isOpenAlert);
		dispatch(deleteStation({ detail: inputValues, navigateHomePage } ));
	};

	const validate = () => {
		let input = inputValues;
		let errors = {};
		let isValid = true;
		if (!input['stationName']) {
			isValid = false;
			errors['stationName'] = 'Please enter Station Name.';
		}
		if (!input['stationLink']) {
			isValid = false;
			errors['stationLink'] = 'Please enter Station Link.';
		} else if (!isUrlValid(input.stationLink)){
			isValid = false;
			errors['stationLink'] = 'Please enter valid Station Link.';
		}
		if (!input['contractExpiredate']) {
			isValid = false;
			errors['contractExpiredate'] = 'Please enter Contract Expiry Date.';
		} else if (!moment(input['contractExpiredate']).isValid()) {
			isValid = false;
			errors['contractExpiredate'] = 'Please enter valid Contract Expiry Date.';
		}
		if (!input['stationDescription']) {
			isValid = false;
			errors['stationDescription'] = 'Please enter Station Description.';
		}
		if (!input['tags'].length) {
			isValid = false;
			errors['tags'] = 'Please enter Tags.';
		}
		if (!input['contentType']) {
			isValid = false;
			errors['contentType'] = 'Please enter Type Of Content.';
		}
		if (!input['stationLocation']) {
			isValid = false;
			errors['stationLocation'] = 'Please enter Station Location.';
		}
		if (!input['organization']) {
			isValid = false;
			errors['organization'] = 'Please enter Organization.';
		}
		if (!input['timeZone']) {
			isValid = false;
			errors['timeZone'] = 'Please enter Time Zone.';
		}
		if (!input['stationImage']) {
			isValid = false;
			errors['image'] = 'Please upload Station Image.';
		}
		setErrors(errors);
		return isValid;
	};
	return (
		<NewStationStyled>
			{stationState.loading && <SimpleBackdrop />}
			{stationState.imageLoading && <SimpleBackdrop />}
			<div className={selectedStation ? 'page-header delete-header' : 'page-header'}>
				<Link to="/" className="back-btn">
					<ArrowBackIosIcon />
				</Link>
				<h3 className="page-title">{selectedStation ? title : 'Create new station'}</h3>
			</div>
			<div className="page-content">
				{selectedStation && <Button className='delete-btn' onClick={() => setIsOpenAlert(true) }>
					Delete station
				</Button>}
				<h4 className="content-title">Stations details</h4>
				<form>
					<Grid container spacing={2}>
						<Grid item xl={5} lg ={6} md={6} xs={12}>
							<div className="from-div">
								<p className="field-input-text">Station Name</p>
								<input
									className="field-input"
									type="text"
									name='stationName'
									value={inputValues.stationName}
									onChange={(e) => handleChange(e)}
								/>
								{errors && errors.stationName && <ErrorMsg error={errors.stationName} />}
							</div>
							<div className="from-div">
								<p className="field-input-text">Station Link</p>
								<input
									className="field-input"
									type="text"
									name='stationLink'
									value={inputValues.stationLink}
									onChange={(e) => handleChange(e)}
								/>
								{errors && errors.stationLink && <ErrorMsg error={errors.stationLink} />}
							</div>
							<div className="from-div date station-date-picker" >
								<p className="field-input-text">Contract Expiry Date</p>
								<DatePicker
									dateFormat="dd/MM/yyyy"
									selected={inputValues.contractExpiredate ? new Date(inputValues.contractExpiredate): null}
									minDate={new Date()}
									locale="en"
									dropdownMode='select'
									placeholderText="DD/MM/YYYY"
									scrollableMonthYearDropdown
									onChange={(date) => setInputValues({ ...inputValues, contractExpiredate: date })}
									customInput={<TextField />}
								/>
								{errors && errors.contractExpiredate && <ErrorMsg error={errors.contractExpiredate} />}
							</div>
							<div className="from-div">
								<p className="field-input-text">Station Description:</p>
								<textarea
									className="field-input"
									rows="4"
									placeholder="Strat typing"
									name='stationDescription'
									value={inputValues.stationDescription}
									onChange={(e) => handleChange(e)}
								/>
								{errors && errors.stationDescription && <ErrorMsg error={errors.stationDescription} />}
							</div>
							<div className="from-div">
								<p className="field-input-text">Tags</p>
								<TagsInput
									selectedTags={(e) => handleSelecetedTags(e)}
									fullWidth
									variant="outlined"
									id="tags"
									name="tags"
									tags={typeof inputValues.tags !== 'string' ? inputValues.tags : []}
									className='tag-input'
								/>
								{errors && errors.tags && <ErrorMsg error={errors.tags} />}
							</div>
							<div className="from-div">
								<p className="field-input-text">Type Of Content</p>
								<input
									className="field-input"
									type="text"
									name='contentType'
									value={inputValues.contentType}
									onChange={(e) => handleChange(e)}
								/>
								{errors && errors.contentType && <ErrorMsg error={errors.contentType} />}
							</div>
							<div className="from-div">
								<p className="field-input-text">Organization</p>
								<input
									className="field-input"
									type="text"
									name='organization'
									value={inputValues.organization}
									onChange={(e) => handleChange(e)}
								/>
								{errors && errors.organization && <ErrorMsg error={errors.organization} />}
							</div>
							<div className="from-div">
								<p className="field-input-text">Station Location</p>
								<input
									className="field-input"
									type="text"
									name='stationLocation'
									value={inputValues.stationLocation}
									onChange={(e) => handleChange(e)}
								/>
								{errors && errors.stationLocation && <ErrorMsg error={errors.stationLocation} />}
							</div>
							<p className="field-input-text">Time Zone</p>
							<FormControl className="time-zone-select">
								<Autocomplete
									id="demo-simple-select"
									onChange={(e, value) => {
										setInputValues({
											...inputValues,
											timeZone: value
										});
									}}
									isOptionEqualToValue={(el, value) => el.name === value.name}
									options={TimeZoneList.map((el) => el.name)}
									value={inputValues.timeZone}
									className="field-input"
									renderInput={(params) => <TextField {...params} placeholder='timezone' />}
								/>
							</FormControl>
							{errors && errors.timeZone && <ErrorMsg error={errors.timeZone} />}
						</Grid>

						<Grid item xl={7} lg={6} md={6} xs={12} className='right-grid'>
							<div className="logo-div">
								{inputValues.stationImage ? <img src={inputValues.stationImage} alt="" /> :  <img src={stationLogo} alt="" />}
							</div>
							{errors && errors.image && <ErrorMsg error={errors.image} image={true} />}
							<Input
								accept=".png"
								style={{ display: 'none' }}
								type="file"
								id="inputImage"
								onChange={(e) => handleUploadFile(e)}
							/>
							<Button
								className="upload-btn"
								onClick={() => uploadFile()}
								disabled={stationState.imageLoading}
							>
								{stationState.imageLoading ? 'Loading...' : selectedStation ? 'Change Logo' :  'Upload Logo'}
							</Button>
							<p className="img-des">
								Image format accepted: png <br />
								Minimum recommended size: 768X432px <br />
								Maximum file size: 5mb
							</p>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item lg={9} md={12} xs={12}>
							{inputValues.users &&
								<UserDetail users={inputValues.users} />
							}
						</Grid>
					</Grid>
					<Grid className="pos-relative" container spacing={2}>
						{stationState.error && <ErrorMsg error={stationState.error} />}
						<Grid item xl={5} lg ={6} md={6} xs={12}>
							<Button
								disabled={stationState.loading}
								className="new-station-button"
								onClick={() => handleSubmit()}
							>
								{stationState.loading ?  'Loading...' : selectedStation ? 'Edit' : 'Create new station'}
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
			<ConfirmDelete open={isOpenAlert} title='Are you sure you want to delete this station?' handleOk={() => handleOK()} setOpen={() => setIsOpenAlert(!isOpenAlert)} />
		</NewStationStyled>
	);
};

export default NewStation;
