import { styled } from '@mui/material/styles';
import { futuraLight, mediumEmphasis, highEmphasis, futura, lightBackgorund, black, white, gray900 } from '../variables';

export const GroupStyled = styled('div')(
	() => `
		.page-header {
			display: flex;	
			justify-content: flex-end;
			align-items: center;
			height: 91px;
			.sort {
				display: flex;
				align-items: center;
				font-family: ${futuraLight};
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 28px;
				color: ${mediumEmphasis};
				margin: 20px 0;
				.sortBar {
					width: 180px;
					height: 40px;
					margin-left: 8px;
					div {
						padding: 0px 8px;
						height: 40px;
						line-height: 38px;
						font-family: ${futuraLight};
						font-size: 16px;
					}	
					.MuiInputLabel-root {
						top: -8px;
						.MuiInputLabel-shrink {
							top: 0px;
						}
						&.Mui-focused {
							top: 0px;
							color: ${highEmphasis};
						} 
					}	
					.Mui-focused {
						.MuiOutlinedInput-notchedOutline {
							border-color: ${highEmphasis};
							border-width: 2px;
							border-radius: 0;
						}
					}
					.MuiOutlinedInput-notchedOutline {
						border-radius: 0;
					}
				}
			}
			.new-station-button {
				max-width: 303px;
				max-height: 48px;
				height: 100%;
				width: 100%;
				border: 1px solid ${highEmphasis};
				border-radius: 0px;
				padding: 0;
				&:hover {
					background-color: ${lightBackgorund};
				} 
				a {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: ${futura};
					font-size: 20px;
					text-decoration: none;
					text-transform: none;
					line-height: 45px;
					letter-spacing: 0.1em;
					color: ${highEmphasis};
					height: 100%;
					.MuiSvgIcon-root {
						margin-right: 5px;
					}
				}
			}
		}
		.card-list {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-left: -16px;
			margin-right: -16px;

            &.dark {
                .card-dark {
					.card-main {
 						.card-title {
							color: ${black};   
						}
						.card-des {
							color: ${black};
						}
						.card-info {
							.card-info-item {
								color: ${black};
							}
							img {
								filter: brightness(100);
							}
						} 
						.card-button {
							background-color: ${white};
							font-family: ${futura};
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							color: ${gray900};
							text-decoration: none;
							text-transform: capitalize;
							border-color: ${white};
							background-color: ${white};
								&:hover {
									background-color: ${black};
									color: ${white};
								}
						}
					}
                }
            }
			@media only screen and (max-width: 991px) { 
				margin-left: -14px;
				margin-right: -14px;
			}
			@media only screen and (max-width: 767px) { 
				margin-left: 0;
				margin-right: 0;
			}
		}
		@media only screen and (max-width: 767px) { 
			.page-header {
				display: flex;	
				justify-content: center;
				align-items: center;
				height: auto;
				min-height: 91px;
				flex-direction: column;
			}
			.card-list {
				justify-content: center;
			}
		}
	`,
);
