import { Box, Button, Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { LoginCard } from '../../../styles/pages/login';
import { handleLogin, closeTooltip } from '../../../redux/slice/authSlice';
import SimpleBackdrop from '../../../components/loader';
import logo from '../../../assets/images/logo-main.svg';
import ErrorMsg from '../../../components/ErrorMsg';
const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const state = useSelector((state) => state.auth);
	const [errMsg, setErrMsg] = useState({});
	const [inputValue, setInputValue] = useState({
		email: '',
		password: '',
	});


	function handleChange(event) {
		const { name, value } = event.target;
		setInputValue({ ...inputValue, [name]: value });
	}
	useEffect(() => {
		const errors = {};
		errors['error'] = state.error;
		setErrMsg(errors);
	}, [state]);

	const LoginForm = (e) => {
		e.preventDefault();
		if (validate()) {
			dispatch(handleLogin(inputValue));
		}
	};

	const validate = () => {
		let input = inputValue;
		let errors = {};
		let isValid = true;
		if (!input['email']) {
			isValid = false;
			errors['email'] = 'Please enter your email Address.';
		} else if (typeof input['email'] !== 'undefined') {

			let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(input['email'])) {
				isValid = false;
				errors['email'] = 'Please enter valid email address.';
			}
		}
		if (!input['password']) {
			isValid = false;
			errors['password'] = 'Please enter your password.';
		}
		setErrMsg(errors);
		return isValid;
	};
	return (
		<LoginCard>
			<Container component="main" maxWidth="xxl">
				{state.loading && <SimpleBackdrop />}
				<Box className="login-container">
					<Box className="logo-div">
						<img src={logo} className="logo" alt="" />
					</Box>
					<Box className="content-div">
						<h1 className="login-page-title">Welcome to Musicalism Admin</h1>
						<input
							className="input-login"
							placeholder="Email"
							variant="outlined"
							name="email"
							value={inputValue.email}
							autoComplete="false"
							onChange={(e) => handleChange(e)}
						/>
						{errMsg && errMsg.email && <ErrorMsg error={errMsg.email} />}
						<input
							className='input-login'
							placeholder='Password'
							type='password'
							name='password'
							autoComplete="false"
							value={inputValue.password}
							onChange={(e) => handleChange(e)}
						/>
						{errMsg && errMsg.password && <ErrorMsg error={errMsg.password} />}
						{errMsg && errMsg.error && <ErrorMsg error={errMsg.error} />}
						<Button disabled={state.loading} className={state.loading ? 'login-button diabled': 'login-button'} variant='contained' onClick={(e) => LoginForm(e)} >{state.loading ? 'Loading...': 'Log in'}</Button>
						<Button onClick={() => navigate('/forgotPassword')} className='forgot-password' >Forgot password</Button>
					</Box>
				</Box>
			</Container>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={state.forgot} autoHideDuration={3000} onClose={() => dispatch(closeTooltip())}>
				<Alert onClose={() => dispatch(closeTooltip())} severity='success' sx={{ width: '100%' }}>
					{state.forgotMsg && state.forgotMsg}
				</Alert>
			</Snackbar>
		</LoginCard>
	);
};

export default Login;
